<template>
	<div>
		<CreatePopup
			:is-open="createPopUpIsOpen"
			popupName="region"
			@hide="(e) => (createPopUpIsOpen = e)"
		/>
		<b-card>
			<b-button
				class="city-button mb-2"
				variant="primary"
				@click="showCreatePopUp"
			>
				<feather-icon icon="PlusIcon" class="mr-25" />
				<span>Create Region</span>
			</b-button>
			<RegionInfoPopup
				v-if="popupIsOpen"
				:is-open="popupIsOpen"
				:region="region"
				@hide="(e) => (popupIsOpen = e)"
			/>
			<div class="scrollme">
				<b-table
					hover
					:items="regionsList"
					:fields="fields"
					class="shadow-sm rounded"
					small
					responsive
					bordered
					empty-text="No matching records found"
					@row-clicked="(e) => onClickRegionRow(e)"
				/>
			</div>
		</b-card>
	</div>
</template>

<script>
import { BTable, BButton, BCard } from 'bootstrap-vue';
import { mapActions, mapState } from 'vuex';
import RegionInfoPopup from './RegionInfoPopup.vue';
import CreatePopup from './CreatePopup.vue';

export default {
	components: {
		BTable,
		BButton,
		RegionInfoPopup,
		CreatePopup,
		BCard,
	},
	data() {
		return {
			regionsList: null,
			popupIsOpen: false,
			createPopUpIsOpen: false,
			fields: [
				{
					key: 'regionId',
					label: 'ID',
				},
				{
					key: 'regionNameEng',
					label: 'Region',
          formatter: (value, key, item) => `${item.regionNameGeo} / ${item.regionNameEng}`,
        },
				{
					key: 'cities[0].cityNameEng',
					label: 'City',
				},
				{
					key: 'cities[0].districts[0].districtNameEng',
					label: 'District',
				},
			],
		};
	},
	computed: {
		...mapState('parametersModule', ['regions', 'region']),
	},
	created() {
		this.getRegions().then(() => {
			this.regionsList = this.regions.data.regions;
		});
	},
	methods: {
		...mapActions('parametersModule', ['getRegions', 'getRegion']),
		onClickRegionRow(e) {
			this.popupIsOpen = !this.popupIsOpen;
			this.getRegion({ id: e.regionId });
		},
		showCreatePopUp() {
			this.createPopUpIsOpen = !this.createPopUpIsOpen;
		},
	},
};
</script>

<style lang="scss" scoped>
.scrollme {
	overflow-x: auto;
}
</style>
