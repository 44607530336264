<template>
	<div class="inputs-wrapper">
		<!-- Region Name -->
		<b-col class="padding-zero">
			<b-form-group label="Country Name" label-for="region-name">
				<div class="d-flex">
					<b-form-input
						id="region-name"
						v-model="region.regionNameGeo"
						type="text"
						placeholder="Geo name"
						class="mr-1"
					/>
					<b-form-input
						id="region-name"
						v-model="region.regionNameEng"
						type="text"
						placeholder="Eng name"
					/>
				</div>
			</b-form-group>
		</b-col>
		<b-col class="padding-zero">
			<div class="city-container">
				<label>{{
					`City in ${region.regionNameGeo} / ${region.regionNameEng}`
				}}</label>
				<b-form-group abel-for="city-name">
					<div class="d-flex">
						<b-form-input
							v-model="region.cityNameGeo"
							placeholder="Geo city name"
							label="Cities"
							:disabled="!region.regionNameEng"
							label-for="Cities"
							class="mr-1"
							@keyup.enter="addCityName"
						/>
						<b-form-input
							v-model="region.cityNameEng"
							placeholder="Eng city Name"
							label="Cities"
							:disabled="!region.regionNameEng"
							label-for="Cities"
							class=""
							@keyup.enter="addCityName"
						/>
					</div>
				</b-form-group>
				<b-button
					v-ripple.400="'rgba(255, 255, 255, 0.15)'"
					class="city-button mb-2"
					variant="primary"
					:disabled="!region.cityNameGeo && !region.citynameEng"
					@click="addCityName"
					@keyup.enter="addCityName"
				>
					<feather-icon icon="PlusIcon" class="mr-25" />
					<span>{{ `Add ` }}</span>
				</b-button>
			</div>
		</b-col>
		<div
			v-for="(city, index) in region.cities"
			:id="city.id"
			:key="city.id"
			class="mb-2 border rounded p-1 shadow-sm"
		>
			<label>
				Districts for {{ `${city.cityNameGeo} / ${city.cityNameEng}` }}
			</label>
			<div
				v-for="(district, index) in city.districts"
				:key="district.id"
				class="mb-2"
			>
				<div class="d-flex">
					<b-form-input
						v-model="district.districtNameGeo"
						placeholder="Geo district name"
						label="Cities"
						label-for="Cities"
						class="mr-1"
					/>
					<b-form-input
						v-model="district.districtNameEng"
						placeholder="Eng district Name"
						label="Cities"
						label-for="Cities"
						class=""
					/>
					<b-button
						v-if="index != 0"
						class="ml-1 btn-icon"
						variant="flat-danger"
						@click="city.districts.splice(index, 1)"
					>
						<feather-icon size="16" icon="TrashIcon" />
					</b-button>
				</div>
			</div>
			<b-button
				variant="primary"
				pill
				class="mr-1"
				size="sm"
				@click="
					{
						{
							city.districts.push({
								districtNameEng: '',
								districtNameGeo: '',
								id: Math.random(),
							});
						}
					}
				"
			>
				Add district
			</b-button>
			<b-button
				variant="danger"
				pill
				size="sm"
				@click="region.cities.splice(index, 1)"
			>
				Remove
			</b-button>
		</div>
		<b-button
			v-ripple.400="'rgba(255, 255, 255, 0.15)'"
			variant="primary"
			@click="save"
		>
			<span>Save</span>
		</b-button>
	</div>
</template>

<script>
import { BFormGroup, BFormInput, BCol, BButton } from 'bootstrap-vue';
// import { heightTransition } from "@core/mixins/ui/transition";
import Ripple from 'vue-ripple-directive';
import Swal from 'sweetalert2';
import { mapActions, mapState } from 'vuex';

export default {
	components: {
		BCol,
		BButton,
		BFormGroup,
		BFormInput,
	},
	directives: {
		Ripple,
	},
	//   mixins: [heightTransition],
	data() {
		return {
			region: {
				cityNameEng: '',
				cityNameGeo: '',
				regionNameEng: 'Georgia',
				regionNameGeo: 'საქართველო',
				cities: [],
			},
		};
	},
	computed: {
		...mapState('parametersModule', ['regions']),
	},
	watch: {
		'region.nameEng': {
			handler(val) {
				this.region.regionGeo = val;
			},
			deep: true,
		},
	},
	methods: {
		...mapActions('parametersModule', ['createRegion']),
		addCityName() {
			const id = Math.random();
			this.region.cities.push({
				cityNameEng: this.region.cityNameEng,
				cityNameGeo: this.region.cityNameGeo,
				districts: [{ districtNameEng: '', districtNameGeo: '', id }],
			});
			this.region.cityNameEng = '';
			this.region.cityNameGeo = '';
		},
		resetForm() {
			this.region = {
				cityName: '',
				regionNameEng: 'Georgia',
				regionNameGeo: 'საქართველო',
				cities: [],
			};
		},
		save() {
			const payload = {
				...this.region,
			};
			payload.cities = payload.cities.map((city) => ({
				...city,
				districts: city.districts.map((d) => ({
					districtNameEng: d.districtNameEng,
					districtNameGeo: d.districtNameGeo,
				})),
			}));
			if (payload.cities.length) {
				this.createRegion(payload).then((response) => {
					if (!response.data.error) {
						this.resetForm();
					}
				});
			} else {
				Swal.fire({
					title: 'დაფიქსირდა შეცდომა!',
					text: 'შესანახად შეიყვანეთ რეგიონი',
					icon: 'error',
					confirmButtonText: 'Try again',
				});
			}
		},
	},
};
</script>

<style lang="scss" scoped>
.repeater-form {
	overflow: hidden;
	transition: 0.35s height;
}
.inputs-wrapper {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
}
.b-form-tag .close {
	display: hide !important;
}
.city-container {
	flex-direction: column;
	.city-button {
		width: 100%;
	}
}
.padding-zero {
	padding-left: 0px;
}
</style>
