<template>
	<b-modal
		id="modal-xl"
		ref="my-modal"
		v-model="isOpen"
		hide-footer
		size="lg"
		centered
		@hide="hideModal"
	>
		<AddRegions v-if="popupName === 'region'" />
		<AddAttributes v-if="popupName === 'attribute'" />
		<AddCategory v-if="popupName === 'category'" />
	</b-modal>
</template>

<script>
import AddRegions from './AddRegions.vue';
import AddAttributes from './AddAttributes.vue';
import AddCategory from './AddCategory.vue';

export default {
	components: {
		AddRegions,
		AddAttributes,
		AddCategory,
	},
	props: {
		isOpen: Boolean,
		popupName: String,
	},
	methods: {
		hideModal() {
			this.$refs['my-modal'].hide();
			this.$emit('hide', false);
		},
	},
};
</script>
