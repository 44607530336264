<template>
  <b-modal
      id="modal-lg modal-scrollable"
      ref="my-modal"
      v-model="isOpen"
      :scrollable="true"
      :title="popupTitle"
      hide-footer
      size="lg"
      @hide="hideModal"
  >
    <div>
      <app-collapse
          v-for="item in region.data.region.cities"
          :key="item.cityId"
          type="margin"
          accordion
      >
        <app-collapse-item :title="`${item.cityNameEng} / ${item.cityNameGeo}`">
          <div class="districts-wrapper">
            <div
                v-for="(district) in item.districts"
                :key="district.districtId"
                class="district"
            >
              {{ `${district.districtNameEng} / ${district.districtNameGeo}` }}
            </div>
          </div>
        </app-collapse-item>
      </app-collapse>
    </div>
  </b-modal>
</template>

<script>
import "vue-good-table/dist/vue-good-table.css";
import AppCollapse from "@core/components/app-collapse/AppCollapse.vue";
import AppCollapseItem from "@core/components/app-collapse/AppCollapseItem.vue";

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
  },
  props: {
    isOpen: Boolean,
    region: Object,
  },
  computed: {
    popupTitle() {
      if (this.region) {
        return `Cities and it's districts of ${this.region.data.region.regionNameEng}`;
      }
      return "";
    },
  },
  methods: {
    hideModal() {
      this.$refs["my-modal"].hide();
      this.$emit("hide", false);
    },
  },
};
</script>

<style lang="scss" scoped>
.card-body {
  display: flex;
}

.districts-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
.district{
  margin: 0 1rem 1rem 0 ;
  border: 1px solid lightgray;
  border-radius: 5px;
  padding: 0.5rem;
}
</style>
